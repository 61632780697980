// Turn off internal auth on localhost and on front door hostnames
const raciFdHostnames = [
  "localhost",
  "ins-dev.ractest.com.au",
  "ins-sit.ractest.com.au",
  "ins-npe.ractest.com.au",
  "ins-uat.ractest.com.au",
  "b2cuat1.ractest.com.au",
  "b2cuat2.ractest.com.au",
  "b2cuat3.ractest.com.au",
  "b2cuat4.ractest.com.au",
  "b2cuat5.ractest.com.au",
  "b2cuat6.ractest.com.au",
  "b2cuat7.ractest.com.au",
  "racinsurance.rac.com.au",
];

const currentHostname = window.location.hostname;

let isFdHostname = false;

if (raciFdHostnames.indexOf(currentHostname) > -1) {
  isFdHostname = true;
}

export const turnOffInternalAuth = isFdHostname;
